import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {FsService} from "./fs.service";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  private shopId: string | null = null;
  constructor(private router: Router,
              private fs: FsService,
              private auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.fs.isInit$.subscribe((isInit: boolean): void => {
      if(isInit){
        this.auth.role$.subscribe((role: "platform" | "anonymous" | "pre-registration" | "reception" | "admin" | null | undefined): boolean => {
          if(route.data['roles'].includes(role) || role === undefined || this.shopId == null){
            //console.warn('auth.guard: autorized');
            return true;
          } else {
            console.warn('auth.guard redirected');
            switch(role){
              case 'admin':
                if(this.fs.shop$.value != null){
                  this.router.navigate(['/admin/statistiques']);
                } else {
                  this.router.navigate(['/admin/my-shops'])
                }
                break;
              case 'reception':
                this.router.navigate(['/reservations']);
                break;
              case 'platform':
                this.router.navigate(['/quai']);
                break;
              case 'pre-registration':
                this.router.navigate(['/']);
                break;
              default: // role == null;
                this.router.navigate(['/']);
                break;
            }
            return false;
          }
        });
      }
    });
    return true // if remove typescript error
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
